import React, { useState, useCallback, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { format, parseISO } from "date-fns";

import makeStyles from '@mui/styles/makeStyles';
import { green } from "@mui/material/colors";
import {
  Button,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Table,
  TableHead,
  Paper,
  Tooltip,
  Typography,
  CircularProgress,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  Edit,
  CheckCircle,
  SignalCellularConnectedNoInternet2Bar,
  SignalCellularConnectedNoInternet0Bar,
  SignalCellular4Bar,
  CropFree,
  DeleteOutline,
  WhatsApp,
  Facebook,
  Instagram
} from "@mui/icons-material";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import api from "../../services/api";
import WhatsAppModal from "../../components/WhatsAppModal";
import ConfirmationModal from "../../components/ConfirmationModal";
import QrcodeModal from "../../components/QrcodeModal";
import { i18n } from "../../translate/i18n";
import { WhatsAppsContext } from "../../context/WhatsApp/WhatsAppsContext";
import toastError from "../../errors/toastError";
import { Can } from "../../components/Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import { getBackendUrl } from "../../config";

const useStyles = makeStyles(theme => ({
  mainPaper: {
    flex: 1,
    margin: theme.spacing(1),
    marginTop: 0,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    fontSize: theme.typography.pxToRem(14),
    border: "1px solid #dadde9",
    maxWidth: 450,
  },
  tooltipPopper: {
    textAlign: "center",
  },
  buttonProgress: {
    color: theme.palette.primary.main,
  },
  iconButton: {
    color: theme.palette.text.secondary
  },
  trashIcon: {
    color: theme.palette.secondary.main
  },
  tableCustomRow: {
    '& td, & th': {
      borderColor: theme.palette.divider,
    },
  },

}));

const CustomToolTip = ({ title, content, children }) => {
  const classes = useStyles();

  return (
    <Tooltip
      arrow
      classes={{
        tooltip: classes.tooltip,
        popper: classes.tooltipPopper,
      }}
      title={
        <React.Fragment>
          <Typography gutterBottom color="inherit">
            {title}
          </Typography>
          {content && <Typography>{content}</Typography>}
        </React.Fragment>
      }
    >
      {children}
    </Tooltip>
  );
};

const Connections = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);
  const { whatsApps, loading } = useContext(WhatsAppsContext);
  const [whatsAppModalOpen, setWhatsAppModalOpen] = useState(false);
  const [connectionType, setConnectionType] = useState(null);
  const [qrModalOpen, setQrModalOpen] = useState(false);
  const [selectedWhatsApp, setSelectedWhatsApp] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [connsLimit, setConnsLimit] = useState(0);


  const confirmationModalInitialState = {
    action: "",
    title: "",
    message: "",
    whatsAppId: "",
    open: false,
  };
  const [confirmModalInfo, setConfirmModalInfo] = useState(
    confirmationModalInitialState
  );

  useEffect(() => {
      const fetchConnsLimit = async () => {
        try {
          const { data } = await api.get("/whatsapplimit/");
          setConnsLimit(data.limit);
        } catch (err) {
          toastError(err);
        }
      };
      fetchConnsLimit();
  }, []);

  const handleStartWhatsAppSession = async whatsAppId => {
    try {
      await api.post(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleRequestNewQrCode = async whatsAppId => {
    try {
      await api.put(`/whatsappsession/${whatsAppId}`);
    } catch (err) {
      toastError(err);
    }
  };

  const handleOpenConnectionModal = (connType) => {
    setSelectedWhatsApp(null);
    setConnectionType(connType);
    handleCloseSelectConnectionType()
    setWhatsAppModalOpen(true);
  };

  const handleCloseConnectionModal = useCallback(() => {
    setWhatsAppModalOpen(false);
    setSelectedWhatsApp(null);
  }, [setSelectedWhatsApp, setWhatsAppModalOpen]);

  const handleOpenQrModal = whatsApp => {

    setSelectedWhatsApp(whatsApp);
    setQrModalOpen(true);
  };
  const handleLoginConnection = (connection) => {
    const host = window.location.protocol + '//' + window.location.host;
    window.location.href = `https://apifb.solubot.com.br/auth/${connection}/?from=${host}&back=${getBackendUrl()}`;
  }
  const handleStartFacebookSession = async (facebookId) => {
    try {
      await api.post(`/facebooksession/${facebookId}`);
    } catch (err) {
      toastError(err);
    }
  }

  const handleCloseQrModal = useCallback(() => {
    setSelectedWhatsApp(null);
    setQrModalOpen(false);
  }, [setQrModalOpen, setSelectedWhatsApp]);

  const handleEditWhatsApp = whatsApp => {
    setSelectedWhatsApp(whatsApp);
    setConnectionType(whatsApp.connectionType);
    setWhatsAppModalOpen(true);
  };

  const handleSelectConnectionType = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelectConnectionType = () => {
    setAnchorEl(null);
  };

  const handleOpenConfirmationModal = (action, whatsAppId) => {
    if (action === "disconnect") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.disconnectTitle"),
        message: i18n.t("connections.confirmationModal.disconnectMessage"),
        whatsAppId: whatsAppId,
      });
    }
    if (action === "disconnectfb") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.disconnectTitle"),
        message: i18n.t("Tem certeza? Você precisará autorizar a conexão novamente"),
        whatsAppId: whatsAppId,
      });
    }

    if (action === "delete") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.deleteTitle"),
        message: i18n.t("connections.confirmationModal.deleteMessage"),
        whatsAppId: whatsAppId,
      });
    }
    if (action === "deletefb") {
      setConfirmModalInfo({
        action: action,
        title: i18n.t("connections.confirmationModal.deleteTitle"),
        message: i18n.t("connections.confirmationModal.deleteMessage"),
        whatsAppId: whatsAppId,
      });
    }
    setConfirmModalOpen(true);
  };

  const handleSubmitConfirmationModal = async () => {
    if (confirmModalInfo.action === "disconnect") {
      try {
        await api.delete(`/whatsappsession/${confirmModalInfo.whatsAppId}`);
      } catch (err) {
        toastError(err);
      }
    }

    if (confirmModalInfo.action === "disconnectfb") {
      try {
        await api.delete(`/facebooksession/${confirmModalInfo.whatsAppId}`);
      } catch (err) {
        toastError(err);
      }
    }

    if (confirmModalInfo.action === "delete") {
      try {
        await api.delete(`/whatsapp/${confirmModalInfo.whatsAppId}`);
        toast.success(i18n.t("connections.toasts.deleted"));
      } catch (err) {
        toastError(err);
      }
    }

    if (confirmModalInfo.action === "deletefb") {
      try {
        await api.delete(`/facebook/${confirmModalInfo.whatsAppId}`);
        toast.success(i18n.t("connections.toasts.deleted"));
      } catch (err) {
        toastError(err);
      }
    }


    setConfirmModalInfo(confirmationModalInitialState);
  };

  const renderActionButtons = whatsApp => {
    if (whatsApp.connectionType === 'facebook' || whatsApp.connectionType === 'instagram') {
      return <>
        {whatsApp.status === "CONNECTED" && (
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => {
              handleOpenConfirmationModal("disconnectfb", whatsApp.id);
            }}
          >
            {i18n.t("connections.buttons.disconnect")}
          </Button>
        )}
        {whatsApp.status === "DISCONNECTED" && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => handleStartFacebookSession(whatsApp.id)}
          >
            {i18n.t("reconectar")}
          </Button>

        )}
        {whatsApp.status === "OPENING" && (
          <Button size="small" variant="outlined" disabled>
            {i18n.t("connections.buttons.connecting")}
          </Button>
        )}
      </>;
    }
    if (whatsApp.connectionType === 'whatsapp' || whatsApp.connectionType === 'bwhatsapp') {
      return <>
        {whatsApp.status === "qrcode" && (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={() => handleOpenQrModal(whatsApp)}
          >
            {i18n.t("connections.buttons.qrcode")}
          </Button>
        )}
        {whatsApp.status === "DISCONNECTED" && (
          <>
            <Button
              size="small"
              variant="outlined"
              color="primary"
              onClick={() => handleStartWhatsAppSession(whatsApp.id)}
            >
              {i18n.t("connections.buttons.tryAgain")}
            </Button>{" "}
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => handleRequestNewQrCode(whatsApp.id)}
            >
              {i18n.t("connections.buttons.newQr")}
            </Button>
          </>
        )}
        {(whatsApp.status === "CONNECTED" ||
          whatsApp.status === "PAIRING" ||
          whatsApp.status === "TIMEOUT") && (
            <Button
              size="small"
              variant="outlined"
              color="secondary"
              onClick={() => {
                handleOpenConfirmationModal("disconnect", whatsApp.id);
              }}
            >
              {i18n.t("connections.buttons.disconnect")}
            </Button>
          )}
        {whatsApp.status === "PENDING" || whatsApp.status === "OPENING" && (
          <Button size="small" variant="outlined" disabled>
            {i18n.t("connections.buttons.connecting")}
          </Button>
        )}

      </>;
    };
  };

  const renderStatusToolTips = whatsApp => {
    if (whatsApp.connectionType === 'facebook' || whatsApp.connectionType === 'instagram') {
      return (
        <div className={classes.customTableCell}>
          {whatsApp.status === "DISCONNECTED" && (
            <CustomToolTip
              title={i18n.t("connections.toolTips.disconnectedfb.title")}
              content={user.profile === 'admin' ? i18n.t("connections.toolTips.disconnectedfb.adminContent") : i18n.t("connections.toolTips.disconnectedfb.userContent")}
            >
              <SignalCellularConnectedNoInternet0Bar color="secondary" />
            </CustomToolTip>
          )}
          {whatsApp.status === "OPENING" && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
          {whatsApp.status === "CONNECTED" && (
            <CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
              <SignalCellular4Bar style={{ color: green[500] }} />
            </CustomToolTip>
          )}
        </div>
      )
    }
    if (whatsApp.connectionType === 'whatsapp' || whatsApp.connectionType === 'bwhatsapp') {
      return (
        <div className={classes.customTableCell}>
          {whatsApp.status === "DISCONNECTED" && (
            <CustomToolTip
              title={i18n.t("connections.toolTips.disconnected.title")}
              content={user.profile === 'admin' ? i18n.t("connections.toolTips.disconnected.adminContent") : i18n.t("connections.toolTips.disconnected.userContent")}
            >
              <SignalCellularConnectedNoInternet0Bar color="secondary" />
            </CustomToolTip>
          )}
          {whatsApp.status === "OPENING" && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
          {whatsApp.status === "qrcode" && (
            <CustomToolTip
              title={i18n.t("connections.toolTips.qrcode.title")}
              content={user.profile === 'admin' ? i18n.t("connections.toolTips.qrcode.adminContent") : i18n.t("connections.toolTips.qrcode.userContent")}
            >
              <CropFree />
            </CustomToolTip>
          )}
          {whatsApp.status === "CONNECTED" && (
            <CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
              <SignalCellular4Bar style={{ color: green[500] }} />
            </CustomToolTip>
          )}
          {(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
            <CustomToolTip
              title={i18n.t("connections.toolTips.timeout.title")}
              content={user.profile === 'admin' ? i18n.t("connections.toolTips.timeout.adminContent") : i18n.t("connections.toolTips.timeout.userContent")}
            >
              <SignalCellularConnectedNoInternet2Bar color="secondary" />
            </CustomToolTip>
          )}
        </div>
      );
    }
  };

  return (
    <>
      <MainContainer>
        <ConfirmationModal
          title={confirmModalInfo.title}
          open={confirmModalOpen}
          onClose={setConfirmModalOpen}
          onConfirm={handleSubmitConfirmationModal}
        >
          {confirmModalInfo.message}
        </ConfirmationModal>
        <QrcodeModal
          open={qrModalOpen}
          onClose={handleCloseQrModal}
          whatsAppId={!whatsAppModalOpen && selectedWhatsApp?.id}
        />
        <WhatsAppModal
          connType={connectionType}
          open={whatsAppModalOpen}
          onClose={handleCloseConnectionModal}
          whatsAppId={!qrModalOpen && selectedWhatsApp?.id}
        />
        <MainHeader>
          <Title>{`${i18n.t("connections.title")} (${whatsApps.length}/${connsLimit})`}</Title>
          <MainHeaderButtonsWrapper>
            <Can
              role={user.profile}
              perform="drawer-admin-items:view"
              yes={() => (
                <>
                  <Button
                    variant="contained"
                    color="primary"
                    aria-controls="connection-menu"
                    aria-haspopup="true"
                    onClick={/*() =>  handleOpenConnectionModal('whatsapp') */handleSelectConnectionType}>
                    {i18n.t("connections.buttons.add")}
                  </Button>
                  <Menu
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: 'center',
                    }}
                    id="connection-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleCloseSelectConnectionType}
                  >
                    <MenuItem onClick={(e) => handleOpenConnectionModal('bwhatsapp')}>
                      <ListItemIcon className={classes.iconButton}>
                        <WhatsApp fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary={i18n.t("connections.buttons.whatsapp")} />
                    </MenuItem>
                    <MenuItem onClick={(e) => handleLoginConnection('facebook')}>
                      <ListItemIcon className={classes.iconButton}>
                        <Facebook fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Facebook (Beta)" />
                    </MenuItem>
                    <MenuItem onClick={(e) => handleLoginConnection('instagram')}>
                      <ListItemIcon className={classes.iconButton}>
                        <Instagram fontSize="small" />
                      </ListItemIcon>
                      <ListItemText primary="Instagram (Beta)" />
                    </MenuItem>
                  </Menu>
                </>
              )}
            />
          </MainHeaderButtonsWrapper>
        </MainHeader>
        <Paper className={classes.mainPaper} variant="outlined">
          <Table size="small">
            <TableHead>
              <TableRow className={classes.tableCustomRow}>
                <TableCell padding="checkbox" />
                <TableCell align="center">
                  {i18n.t("connections.table.name")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("connections.table.whatsappNumber")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("connections.table.status")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("connections.table.history")}
                </TableCell>
                <Can
                  role={user.profile}
                  perform="drawer-admin-items:view"
                  yes={() => (
                    <TableCell align="center">
                      {i18n.t("connections.table.session")}
                    </TableCell>
                  )}
                />
                <TableCell align="center">
                  {i18n.t("connections.table.lastUpdate")}
                </TableCell>
                <TableCell align="center">
                  {i18n.t("connections.table.default")}
                </TableCell>
                <Can
                  role={user.profile}
                  perform="drawer-admin-items:view"
                  yes={() => (
                    <TableCell align="center">
                      {i18n.t("connections.table.actions")}
                    </TableCell>
                  )}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? <TableRowSkeleton /> :
                <>
                  {whatsApps?.length > 0 && whatsApps.map(whatsApp => (
                    <TableRow className={classes.tableCustomRow} key={whatsApp.id}>
                      <TableCell align="center">
                        {(whatsApp.connectionType === 'whatsapp' || whatsApp.connectionType === 'bwhatsapp') && <WhatsApp className={classes.iconButton} />}
                        {whatsApp.connectionType === 'facebook' && <Facebook className={classes.iconButton} />}
                        {whatsApp.connectionType === 'instagram' && <Instagram className={classes.iconButton} />}
                      </TableCell>
                      <TableCell align="center">{whatsApp.name}</TableCell>
                      <TableCell align="center">
                        {whatsApp.whatsappNumber ? (whatsApp.whatsappNumber) : ("---")}
                      </TableCell>
                      <TableCell align="center">
                        {renderStatusToolTips(whatsApp)}
                      </TableCell>
                      <TableCell align="center">
                        {
                          (whatsApp.connectionType === 'whatsapp' || whatsApp.connectionType === 'bwhatsapp' && <WhatsApp className={classes.iconButton} />) ?
                            whatsApp.importHistory === true ? 'Ativado' : 'Desativado'
                            :
                            "---"
                        }
                      </TableCell>
                      <Can
                        role={user.profile}
                        perform="drawer-admin-items:view"
                        yes={() => (
                          <TableCell align="center">
                            {renderActionButtons(whatsApp)}
                          </TableCell>
                        )}
                      />
                      <TableCell align="center">
                        {format(parseISO(whatsApp.updatedAt), "dd/MM/yy HH:mm")}
                      </TableCell>
                      <TableCell align="center">
                        {whatsApp.isDefault && (
                          <div className={classes.customTableCell}>
                            <CheckCircle style={{ color: green[500] }} />
                          </div>
                        )}
                      </TableCell>
                      <Can
                        role={user.profile}
                        perform="drawer-admin-items:view"
                        yes={() => (
                          <TableCell align="center">
                            <IconButton
                              size="small"
                              className={classes.iconButton}
                              onClick={() => handleEditWhatsApp(whatsApp)}
                            >
                              <Edit />
                            </IconButton>

                            <IconButton
                              size="small"
                              className={classes.trashIcon}
                              onClick={e => {
                                handleOpenConfirmationModal((whatsApp.connectionType === "facebook" ? "deletefb" : "delete"), whatsApp.id);
                              }}
                            >
                              <DeleteOutline />
                            </IconButton>
                          </TableCell>
                        )}
                      />
                    </TableRow>
                  ))}
                </>
              }
            </TableBody>
          </Table>
        </Paper>
      </MainContainer>
    </>
  );
};

export default Connections;