import { Divider, FormControl, IconButton, MenuItem, Paper, Select, Tooltip, Typography } from "@mui/material";
import { i18n } from "../../translate/i18n";
import { Help } from "@mui/icons-material";
import React, { useContext } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { AuthContext } from "../../context/Auth/AuthContext";
import useSettings, { changeSettings } from "../../hooks/useSettings";
import MarkdownWrapper from "../../components/MarkdownWrapper";

const useStyles = makeStyles(theme => ({
    mainpaper: {
        padding: theme.spacing(2),
        alignItems: "center",
        marginBottom: 12,
    },
    heading: {
        fontSize: theme.typography.pxToRem(17),
        fontWeight: theme.typography.fontWeightMedium,
    },
    settingOption: {
        marginLeft: "auto",
    },
    paper: {
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        backgroundColor: 'transparent'
    },
    childpaper: {
        backgroundColor: theme.palette.background.default + '5b'
    },
    subheader: {
        margin: "2px 15px"
    },
    iconButton: {
        color: theme.palette.text.secondary
    },
}))
const TicketsSettings = () => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const { settings } = useSettings();

    const getSettingValue = key => {
        const { value } = settings.find(s => s.key === key);
        return value;
    };
    return (
        <Paper elevation={0} variant="outlined" className={classes.childpaper}>
            <Typography className={classes.subheader} variant="body2" color="textSecondary">
                Fechamento de ticket
            </Typography>
            <Paper elevation={0} className={classes.paper}>
                <Typography variant="body1">
                    {`${i18n.t("settings.requireticketsubject.name")} `}
                </Typography>
                <FormControl className={classes.settingOption}>
                    <Select
                        margin="dense"
                        id="requireticketsubject-setting"
                        variant="outlined"
                        name="requireticketsubject"
                        value={(settings && settings.length > 0) ? getSettingValue("requireticketsubject") : 'disabled'}
                        onChange={changeSettings}
                        className={classes.settingOption}
                    >
                        <MenuItem value="enabled">
                            {i18n.t("settings.requireticketsubject.options.enabled")}</MenuItem>
                        <MenuItem value="disabled">
                            {i18n.t("settings.requireticketsubject.options.disabled")}</MenuItem>
                    </Select>
                </FormControl>
                <Tooltip arrow interactive title={
                    <p>
                        <MarkdownWrapper>{i18n.t('settings.helpers.requireticketsubject')}</MarkdownWrapper>
                    </p>
                }
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>
            <Paper elevation={0} className={classes.paper}>
                <Typography variant="body1">
                    {`${i18n.t("settings.showfarewellmessageswitch.name")} `}
                </Typography>
                <FormControl className={classes.settingOption}>
                    <Select
                        margin="dense"
                        id="showfarewellmessageswitch-setting"
                        variant="outlined"
                        name="showfarewellmessageswitch"
                        value={(settings && settings.length > 0) ? getSettingValue("showfarewellmessageswitch") : 'disabled'}
                        onChange={changeSettings}
                        className={classes.settingOption}
                    >
                        <MenuItem value="enabled">
                            {i18n.t("settings.showfarewellmessageswitch.options.enabled")}</MenuItem>
                        <MenuItem value="disabled">
                            {i18n.t("settings.showfarewellmessageswitch.options.disabled")}</MenuItem>
                    </Select>
                </FormControl>
                <Tooltip arrow interactive title={
                    <p>
                        <MarkdownWrapper>{i18n.t('settings.helpers.showfarewellmessageswitch')}</MarkdownWrapper>
                    </p>
                }
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>
            <Paper elevation={0} className={classes.paper}>
                <Typography variant="body1">
                    {`${i18n.t("settings.showreviewmessageswitch.name")} `}
                </Typography>
                <FormControl className={classes.settingOption}>
                    <Select
                        margin="dense"
                        id="showreviewmessageswitch-setting"
                        variant="outlined"
                        name="showreviewmessageswitch"
                        value={(settings && settings.length > 0) ? getSettingValue("showreviewmessageswitch") : 'disabled'}
                        onChange={changeSettings}
                        className={classes.settingOption}
                    >
                        <MenuItem value="enabled">
                            {i18n.t("settings.showreviewmessageswitch.options.enabled")}</MenuItem>
                        <MenuItem value="disabled">
                            {i18n.t("settings.showreviewmessageswitch.options.disabled")}</MenuItem>
                    </Select>
                </FormControl>
                <Tooltip arrow interactive title={
                    <p>
                        <MarkdownWrapper>{i18n.t('settings.helpers.showreviewmessageswitch')}</MarkdownWrapper>
                    </p>
                }
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>
        <Divider />
            <Typography className={classes.subheader} variant="body2" color="textSecondary">
                Privacidade
            </Typography>
            <Paper elevation={0} className={classes.paper}>
                <Typography variant="body1">
                    {`${i18n.t("settings.anonymoustickets.name")} `}
                </Typography>

                <FormControl className={classes.settingOption}>
                    <Select
                        margin="dense"
                        id="anonymoustickets-setting"
                        variant="outlined"
                        name="anonymoustickets"
                        value={(settings && settings.length > 0) ? getSettingValue("anonymoustickets") : 'disabled'}
                        onChange={changeSettings}
                        className={classes.settingOption}
                    >
                        <MenuItem value="enabled">
                            {i18n.t("settings.anonymoustickets.options.enabled")}</MenuItem>
                        <MenuItem value="disabled">
                            {i18n.t("settings.anonymoustickets.options.disabled")}</MenuItem>
                    </Select>
                </FormControl>
                <Tooltip arrow interactive title={
                    <p>
                        <MarkdownWrapper>{i18n.t('settings.helpers.anonymousTickets')}</MarkdownWrapper>
                    </p>
                }
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>

            <Paper elevation={0} className={classes.paper}>
                <Typography variant="body1">
                    {`${i18n.t("settings.privateticket.name")} `}
                </Typography>

                <FormControl className={classes.settingOption}>
                    <Select
                        margin="dense"
                        id="privateticket-setting"
                        variant="outlined"
                        name="privateticket"
                        value={(settings && settings.length > 0) ? getSettingValue("privateticket") : 'disabled'}
                        onChange={changeSettings}
                        className={classes.settingOption}
                    >
                        <MenuItem value="forusers">
                            {i18n.t("settings.privateticket.options.forusers")}</MenuItem>
                        <MenuItem value="forqueues">
                            {i18n.t("settings.privateticket.options.forqueues")}</MenuItem>
                        <MenuItem value="disabled">
                            {i18n.t("settings.privateticket.options.disabled")}</MenuItem>
                    </Select>
                </FormControl>
                <Tooltip arrow interactive title={
                    <p>
                        <MarkdownWrapper>{i18n.t('settings.helpers.privateticket')}</MarkdownWrapper>
                    </p>}
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>
            <Paper elevation={0} className={classes.paper}>
                <Typography variant="body1">
                    {`${i18n.t("settings.seeAllTickets.name")} `}
                </Typography>

                <FormControl className={classes.settingOption}>
                    <Select
                        margin="dense"
                        id="seealltickets-setting"
                        variant="outlined"
                        name="seealltickets"
                        value={(settings && settings.length > 0) && getSettingValue("privateticket") === "disabled" ? getSettingValue("seealltickets") : 'disabled'}
                        onChange={changeSettings}
                        className={classes.settingOption}
                        disabled={(settings && settings.length > 0) && getSettingValue("privateticket") === "forusers" ? true : false}
                    >
                        <MenuItem value="enabled">
                            {i18n.t("settings.seeAllTickets.options.enabled")}</MenuItem>
                        <MenuItem value="disabled">
                            {i18n.t("settings.seeAllTickets.options.disabled")}</MenuItem>
                    </Select>
                </FormControl>
                <Tooltip arrow interactive title={
                    <React.Fragment>
                        <p>{i18n.t('settings.helpers.seeAllTickets')}</p>
                    </React.Fragment>}
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>
            <Divider />
            <Typography className={classes.subheader} variant="body2" color="textSecondary">
                Ordenação
            </Typography>
            <Paper elevation={0} className={classes.paper}>
                <Typography variant="body1">
                    {`${i18n.t("settings.pendingticketorder.name")} `}
                </Typography>

                <FormControl className={classes.settingOption}>
                    <Select
                        margin="dense"
                        id="pendingticketorder-setting"
                        variant="outlined"
                        name="pendingticketorder"
                        value={(settings && settings.length > 0) ? getSettingValue("pendingticketorder") : 'lastmsg'}
                        onChange={changeSettings}
                        className={classes.settingOption}
                    >
                        <MenuItem value="lastmsg">
                            {i18n.t("settings.pendingticketorder.options.lastmsg")}</MenuItem>
                        <MenuItem value="createdat">
                            {i18n.t("settings.pendingticketorder.options.createdAt")}</MenuItem>
                    </Select>
                </FormControl>
                <Tooltip arrow interactive title={
                    <React.Fragment>
                        <p>{i18n.t('settings.helpers.pendingticketorder')}</p>
                    </React.Fragment>}
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>

            <Paper elevation={0} className={classes.paper}>
                <Typography variant="body1">
                    {`${i18n.t("settings.openticketorder.name")} `}
                </Typography>

                <FormControl className={classes.settingOption}>
                    <Select
                        margin="dense"
                        id="openticketorder-setting"
                        variant="outlined"
                        name="openticketorder"
                        value={(settings && settings.length > 0) ? getSettingValue("openticketorder") : 'lastmsg'}
                        onChange={changeSettings}
                        className={classes.settingOption}
                    >
                        <MenuItem value="lastmsg">
                            {i18n.t("settings.openticketorder.options.lastmsg")}</MenuItem>
                        <MenuItem value="createdat">
                            {i18n.t("settings.openticketorder.options.createdAt")}</MenuItem>
                    </Select>
                </FormControl>
                <Tooltip arrow interactive title={
                    <React.Fragment>
                        <p>{i18n.t('settings.helpers.openticketorder')}</p>
                    </React.Fragment>}
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>
            <Divider />
            <Typography className={classes.subheader} variant="body2" color="textSecondary">
                Transferência
            </Typography>
            <Paper elevation={0} className={classes.paper}>

                <Typography variant="body1">
                    {i18n.t("settings.transfermsg.name")}
                </Typography>

                <FormControl className={classes.settingOption}>
                    <Select
                        margin="dense"
                        id="transfermsg-setting"
                        variant="outlined"
                        name="transfermsg"
                        value={(settings && settings.length > 0) ? getSettingValue("transfermsg") : 'disabled'}
                        onChange={changeSettings}
                        className={classes.settingOption}
                    >
                        <MenuItem value="enabled">
                            {i18n.t("settings.transfermsg.options.enabled")}
                        </MenuItem>
                        <MenuItem value="simplified">
                            {i18n.t("settings.transfermsg.options.simplified")}
                        </MenuItem>
                        <MenuItem value="disabled">
                            {i18n.t("settings.transfermsg.options.disabled")}
                        </MenuItem>
                    </Select>
                </FormControl>
                <Tooltip arrow interactive title={
                    <div style={{
                        display: "flex", overflowWrap: "break-word",
                        padding: "5px 5px 5px 5px",
}}>
                        <MarkdownWrapper>{i18n.t('settings.helpers.transferMsg')}</MarkdownWrapper>
                    </div>
                }
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>

            <Paper elevation={0} className={classes.paper}>

                <Typography variant="body1">
                    {i18n.t("settings.showtransferswitch.name")}
                </Typography>

                <FormControl className={classes.settingOption}>
                    <Select
                        margin="dense"
                        id="showtransferswitch-setting"
                        variant="outlined"
                        name="showtransferswitch"
                        value={(settings && settings.length > 0) && getSettingValue("transfermsg") !== "disabled" ? getSettingValue("showtransferswitch") : 'disabled'}
                        onChange={changeSettings}
                        className={classes.settingOption}
                        disabled={(settings && settings.length > 0) && getSettingValue("transfermsg") === "disabled" ? true : false}
                    >
                        <MenuItem value="enabled">
                            {i18n.t("settings.showtransferswitch.options.enabled")}</MenuItem>
                        <MenuItem value="disabled">
                            {i18n.t("settings.showtransferswitch.options.disabled")}</MenuItem>
                    </Select>
                </FormControl>
                <Tooltip arrow interactive title={
                    <React.Fragment>
                        <p>{i18n.t('settings.helpers.showtransferswitch')}</p>
                    </React.Fragment>}
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>
            <Divider />
            <Typography className={classes.subheader} variant="body2" color="textSecondary">
                Grupos
            </Typography>
            <Paper elevation={0} className={classes.paper}>
                <Typography variant="body1">
                    {`${i18n.t("settings.groupsasticket.name")} `}
                </Typography>

                <FormControl className={classes.settingOption}>
                    <Select
                        margin="dense"
                        id="groupsasticket-setting"
                        variant="outlined"
                        name="groupsasticket"
                        value={(settings && settings.length > 0) ? getSettingValue("groupsasticket") : 'disabled'}
                        onChange={changeSettings}
                        className={classes.settingOption}
                    >
                        <MenuItem value="enabled">
                            {i18n.t("settings.groupsasticket.options.enabled")}</MenuItem>
                        <MenuItem value="disabled">
                            {i18n.t("settings.groupsasticket.options.disabled")}</MenuItem>
                    </Select>
                </FormControl>
                <Tooltip arrow interactive title={
                    <React.Fragment>
                        <p>{i18n.t('settings.helpers.groupsasticket')}</p>
                    </React.Fragment>}
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>
            <Paper elevation={0} className={classes.paper}>
                <Typography variant="body1">
                    {`${i18n.t("settings.reopenlastgroupticket.name")} `}
                </Typography>

                <FormControl className={classes.settingOption}>
                    <Select
                        margin="dense"
                        id="reopenlastgroupticket-setting"
                        variant="outlined"
                        name="reopenlastgroupticket"
                        value={(settings && settings.length > 0) && getSettingValue("groupsasticket") === "enabled" ? getSettingValue("reopenlastgroupticket") : "enabled"}
                        onChange={changeSettings}
                        disabled={(settings && settings.length > 0) && getSettingValue("groupsasticket") === "disabled" ? true : false}

                        className={classes.settingOption}
                    >
                        <MenuItem value="enabled">
                            {i18n.t("settings.reopenlastgroupticket.options.enabled")}</MenuItem>
                        <MenuItem value="disabled">
                            {i18n.t("settings.reopenlastgroupticket.options.disabled")}</MenuItem>
                    </Select>
                </FormControl>
                <Tooltip arrow interactive title={
                    <React.Fragment>
                        <p>{i18n.t('settings.helpers.reopenlastgroupticket')}</p>
                    </React.Fragment>}
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>
            <Divider />
            <Typography className={classes.subheader} variant="body2" color="textSecondary">
                Outros
            </Typography>

            <Paper elevation={0} className={classes.paper}>
                <Typography variant="body1">
                    {`${i18n.t("settings.acceptonlyfirst.name")} `}
                </Typography>

                <FormControl className={classes.settingOption}>
                    <Select
                        margin="dense"
                        id="acceptonlyfirst-setting"
                        variant="outlined"
                        name="acceptonlyfirst"
                        value={(settings && settings.length > 0) ? getSettingValue("acceptonlyfirst") : 'disabled'}
                        onChange={changeSettings}
                        className={classes.settingOption}
                    >
                        <MenuItem value="enabled">
                            {i18n.t("settings.acceptonlyfirst.options.enabled")}</MenuItem>
                        <MenuItem value="disabled">
                            {i18n.t("settings.acceptonlyfirst.options.disabled")}</MenuItem>
                        <MenuItem value="firstofqueue">
                            {i18n.t("settings.acceptonlyfirst.options.firstofqueue")}</MenuItem>
                    </Select>
                </FormControl>
                <Tooltip arrow interactive title={
                    <React.Fragment>
                        <p>{i18n.t('settings.helpers.acceptonlyfirst')}</p>
                    </React.Fragment>}
                >
                    <IconButton
                        size="small"
                        className={classes.iconButton}
                    >
                        <Help />
                    </IconButton>
                </Tooltip>
            </Paper>
            
        </Paper>
    )
}
export default TicketsSettings;