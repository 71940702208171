import React, { useEffect, useState } from "react";
import {
  AccordionDetails,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { CheckCircle, CropFree, SignalCellular4Bar, SignalCellularConnectedNoInternet0Bar, SignalCellularConnectedNoInternet2Bar } from "@mui/icons-material";
import { i18n } from "../../translate/i18n";
import { green } from "@mui/material/colors";
import { getBackendUrl } from "../../config";
import useWhatsApps from "../../hooks/useWhatsApps";
import useSettings from "../../hooks/useSettings";
import useQueues from "../../hooks/useQueues";
import useUsers from "../../hooks/useUsers";

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    alignItems: "center",
    marginBottom: 12,
  },
  heading: {
    fontSize: theme.typography.pxToRem(17),
    fontWeight: theme.typography.fontWeightMedium,
  },
  mainpaper: {
    padding: theme.spacing(2),
    alignItems: "center",
    marginBottom: 12,
    backgroundColor: 'transparent'
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    backgroundColor: 'transparent'
  },
  childpaper: {
    backgroundColor: theme.palette.background.default + '5b'
  },
  iconButton: {
    color: theme.palette.text.secondary
  },
  rawExampleSectionCode: {
    backgroundColor: "#212121",
    padding: '5px',
    borderRadius: '5px',
    color: "#ffffff"
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const IntegrationSettings = ({ apiToken }) => {
  const classes = useStyles();
  const { whatsApps } = useWhatsApps();
  const { findAll } = useQueues();
  const [queues, setQueues] = useState();
  const { users } = useUsers({ searchParam: undefined, pageNumber: undefined });
  const { settings } = useSettings();

  useEffect(() => {
    const loadQueues = async () => {
      const list = await findAll();
      //setAllQueues(list);
      setQueues(list);
    }
    loadQueues();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSettingValue = key => {
    const { value } = settings.find(s => s.key === key);
    return value;
  };

  const renderStatusToolTips = whatsApp => {
    return (
      <div className={classes.customTableCell}>
        {whatsApp.status === "DISCONNECTED" && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.disconnected.title")}
            content={i18n.t("connections.toolTips.disconnected.content")}
          >
            <SignalCellularConnectedNoInternet0Bar color="secondary" />
          </CustomToolTip>
        )}
        {whatsApp.status === "OPENING" && (
          <CircularProgress size={24} className={classes.buttonProgress} />
        )}
        {whatsApp.status === "qrcode" && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.qrcode.title")}
            content={i18n.t("connections.toolTips.qrcode.content")}
          >
            <CropFree />
          </CustomToolTip>
        )}
        {whatsApp.status === "CONNECTED" && (
          <CustomToolTip title={i18n.t("connections.toolTips.connected.title")}>
            <SignalCellular4Bar style={{ color: green[500] }} />
          </CustomToolTip>
        )}
        {(whatsApp.status === "TIMEOUT" || whatsApp.status === "PAIRING") && (
          <CustomToolTip
            title={i18n.t("connections.toolTips.timeout.title")}
            content={i18n.t("connections.toolTips.timeout.content")}
          >
            <SignalCellularConnectedNoInternet2Bar color="secondary" />
          </CustomToolTip>
        )}
      </div>
    );
  }
  const CustomToolTip = ({ title, content, children }) => {
    const classes = useStyles();

    return (
      <Tooltip
        arrow
        classes={{
          tooltip: classes.tooltip,
          popper: classes.tooltipPopper,
        }}
        title={
          <React.Fragment>
            <Typography gutterBottom color="inherit">
              {title}
            </Typography>
            {content && <Typography>{content}</Typography>}
          </React.Fragment>
        }
      >
        {children}
      </Tooltip>
    );
  };
  return (
    <Paper elevation={0} variant="outlined" className={classes.childpaper}>
      <AccordionDetails>
        {i18n.t("settings.integration.description")}
      </AccordionDetails>
      <Paper elevation={0} className={classes.mainpaper}>
        <Typography variant="body1">
          {i18n.t("settings.integration.apiUrl")}{':'}
        </Typography>
        <pre className={classes.rawExampleSectionCode}>
          <code>
            {`POST\n${getBackendUrl()}/api/messages/send`}
          </code>
        </pre>
        <Typography variant="body1">
          {i18n.t("settings.integration.headersJson")}{':'}
        </Typography>
        <pre className={classes.rawExampleSectionCode}>
          <code>
            {`{\n	"Authorization":"Bearer ${settings && settings.length > 0 && getSettingValue("userApiToken")}",\n	"Content-Type": "application/json"\n}`}
          </code>
        </pre>

        <Typography variant="body1">
          {i18n.t("settings.integration.jsonFormat")}{':'}
        </Typography>
        <pre className={classes.rawExampleSectionCode}>
          <code>
            {
              '{\n' +
              `"number": "<${i18n.t("settings.integration.examples.number")}>", //String\n` +
              `"body": "<${i18n.t("settings.integration.examples.body")}>", //String\n` +
              `"userId": <${i18n.t("settings.integration.examples.userId")}>, //Int\n` +
              `"queueId": <${i18n.t("settings.integration.examples.queueId")}>, //Int\n` +
              `"tags": ["<${i18n.t("settings.integration.examples.tag")}>","<${i18n.t("settings.integration.examples.tag")}>"], //String[]\n` +
              `"whatsappId": <${i18n.t("settings.integration.examples.whatsappId")}> //Int\n` +
              '}'
            }
          </code>
        </pre>

        <div style={{height:'30px'}}></div>

        <Typography variant="body1">
          {i18n.t("settings.integration.headersFormdata")}{':'}
        </Typography>
        <pre className={classes.rawExampleSectionCode}>
          <code>
            {`{\n	"Authorization":"Bearer ${settings && settings.length > 0 && getSettingValue("userApiToken")}",\n	"Content-Type": "multipart/form-data"\n}`}
          </code>
        </pre>
        <Typography variant="body1">
          {i18n.t("settings.integration.mediasFormat")}{':'}
        </Typography>
        <pre className={classes.rawExampleSectionCode}>
          <code>
            {
              '\n' +
              `number: <${i18n.t("settings.integration.examples.number")}> \n` +
              `body: <${i18n.t("settings.integration.examples.body")}> \n` +
              `userId: <${i18n.t("settings.integration.examples.userId")}> \n` +
              `queueId: <${i18n.t("settings.integration.examples.queueId")}> \n` +
              `tags[]: <${i18n.t("settings.integration.examples.tag")}> \n` +
              `Medias: <${i18n.t("settings.integration.examples.medias")}> \n` +
              `whatsappId: <${i18n.t("settings.integration.examples.whatsappId")}> \n` +
              '\n'
            }
          </code>
        </pre>
        <Divider />

        <Typography variant="body1">
          {i18n.t("settings.integration.connections")}{':'}
        </Typography>
        <List fullWidth>
          {whatsApps.map((whatsapp) => (
            <ListItem>
              <ListItemText primary={whatsapp.id} />
              <ListItemText primary={whatsapp.name} />
              <ListItemIcon>
                {renderStatusToolTips(whatsapp)}
              </ListItemIcon>
              <ListItemIcon>
                {whatsapp.isDefault && (
                  <div className={classes.customTableCell}>
                    <CheckCircle style={{ color: green[500] }} />
                  </div>
                )}
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
        <Divider />
        <Typography variant="body1">
          {i18n.t("settings.integration.queues")}{':'}
        </Typography>
        <List fullWidth>
          {queues && queues.length > 0 && queues.map((queue) => (
            <ListItem>
              <ListItemText primary={queue.id} />
              <ListItemText primary={queue.name} />
              <ListItemIcon>
                <div className={classes.customTableCell}>
                  <span
                    style={{
                      backgroundColor: queue.color,
                      width: 60,
                      height: 20,
                      borderRadius: '50px',
                      alignSelf: "center",
                    }}
                  />
                </div>
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
        <Divider />
        <Typography variant="body1">
          {i18n.t("settings.integration.users")}{':'}
        </Typography>
        <List fullWidth>
          {users && users.length > 0 && users.map((user) => (
            <ListItem>
              <ListItemText primary={user.id} />
              <ListItemText primary={user.name} />
              <ListItemText primary={user.email} />
            </ListItem>
          ))}
        </List>
      </Paper>
    </Paper>

  )
}
export default IntegrationSettings;