import { Box, ListItemButton, ListItemIcon, ListItemText, Paper, Tab } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import makeStyles from "@mui/styles/makeStyles/makeStyles";
import React, { useContext, useState } from "react";
import UserSettings from "./userSettings";
import IntegrationSettings from "./integrationSettings";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainContainer from "../../components/MainContainer";
import GeneralSettings from "./generalSettings";
import TicketsSettings from "./ticketsSettings";
import { getAppname, getVersion } from "../../config";
import { Can } from "../../components/Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import { i18n } from "../../translate/i18n";
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        margin: theme.spacing(1),
        marginTop: 0,
        padding: 0,
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    tabPanel: {
        padding: theme.spacing(1),

    },
}))

function ListItemLink(props) {
    const { icon, primary, to, className } = props;
    const classes = useStyles();

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} />
            )),
        [to]
    );

    return (
        <div>
            <ListItemButton component={renderLink} className={className}>
                {icon ? <ListItemIcon className={classes.icon}>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItemButton>
        </div>
    );
}

const Settings = () => {
    const classes = useStyles();
    const [value, setValue] = useState('user');
    const { user } = useContext(AuthContext);
    const [version, setVersion] = useState({});

    const fetchVersion = async () => {
        if (!version.version)
            setVersion(await getVersion());
    }
    fetchVersion()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <MainContainer>
            <MainHeader>
                <Title>{i18n.t("settings.title")}</Title>
            </MainHeader>
            <Can
                role={user.profile}
                perform="drawer-admin-items:view"
                yes={() => (
                    <Paper
                        className={classes.mainPaper}
                        variant="outlined"
                    //onScroll={handleScroll}
                    >
                        <Box sx={{ width: '100%' }}>
                            <TabContext value={value}>
                                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                    <TabList
                                        //value={value}
                                        variant="fullWidth"
                                        onChange={handleChange}
                                        textColor="primary"
                                        indicatorColor="primary"
                                        aria-label="secondary tabs example"
                                    >
                                        <Tab value="user" label={i18n.t("users.title")} />
                                        <Tab value="general" label={i18n.t("settings.general.title")} />
                                        <Tab value="tickets" label="Tickets" />
                                        <Tab value="integration" label={i18n.t("settings.integration.title")} />
                                    </TabList>
                                </Box>

                                <TabPanel
                                    className={classes.tabPanel}
                                    value="user"
                                >
                                    <UserSettings />
                                </TabPanel>
                                <TabPanel
                                    className={classes.tabPanel}
                                    value="general"
                                >
                                    <GeneralSettings />
                                </TabPanel>
                                <TabPanel
                                    className={classes.tabPanel}
                                    value="tickets"
                                >
                                    <TicketsSettings />
                                </TabPanel>
                                <TabPanel
                                    className={classes.tabPanel}
                                    value="integration"
                                >
                                    <IntegrationSettings />
                                </TabPanel>
                            </TabContext>
                        </Box>

                    </Paper>
                )}
                no={() => (
                    <Paper
                        className={classes.mainPaper}
                        variant="outlined"
                        style={{ padding: 8 }}
                    //onScroll={handleScroll}
                    >
                        <UserSettings />
                    </Paper>
                )}
            />
            {version.version &&
                <ListItemLink
                    to="/changelog"
                    primary={`${getAppname()} v${version.version}-${version.apiVersion}.${version.api2Version}`}
                    className={classes.versionLabel}
                />
            }
        </MainContainer>
    );
}
export default Settings;