import React, { useEffect, useState } from "react";
import { AppBar, Avatar, Button, Box, Checkbox, Chip, FormControlLabel, FormGroup, Paper, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs, Tooltip, Typography, TextField, InputAdornment, FormControl, Select, InputLabel, MenuItem, Divider, ButtonGroup } from "@mui/material";
import { i18n } from "../../translate/i18n";
import { Info, Loop, Search, SimCardDownloadOutlined } from "@mui/icons-material";
import AvatarTooltip from "../AvatarTooltip";
import useContacts from "../../hooks/useContacts";
import toastError from "../../errors/toastError";
import TableRowSkeleton from "../TableRowSkeleton";
import { format } from "date-fns";
import api from "../../services/api";
import useTags from "../../hooks/useTags";
import { toast } from "react-toastify";
import TabList from "@mui/lab/TabList/TabList";
import TabContext from "@mui/lab/TabContext/TabContext";
import TabPanel from "@mui/lab/TabPanel/TabPanel";
import ImportContactsFromFile from "../ImportContactsFromFile";
import { downloadExampleCSV } from "../../helpers/downloadExampleCSV";


const CampaignContacts = ({ classes, values, campaign }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [tagFilter, setTagFilter] = useState('');
  const { tags } = useTags({ showAll: true });
  const { contacts, loading, hasMore, count } = useContacts({ searchParam, pageNumber, setPageNumber, tagFilter });
  const [selectedContacts, setSelectedContacts] = useState(values.contacts);
  //const [listedContacts, setContacts] = useState([]);
  const [historyContacts, setHistoryContacts] = useState([]);
  const [ignoreSent, setIgnoreSent] = useState(false);
  const [hideSent, setHideSent] = useState(false);
  const maxSelect = 500;
  const [value, setValue] = useState("selected");
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [importFile, setImportFile] = useState(null);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    values.contacts = selectedContacts;
  }, [selectedContacts])

  useEffect(() => {
    (async () => {

      if (!campaign.id) return;
      try {
        const { data } = await api.get(`/campaigns/${campaign.id}/history`);
        setHistoryContacts(data)
      } catch (err) {
        toastError(err);
      }
    })();
  }, [campaign.id]);

  const handleChange = e => {
    let value = +e.target.value;
    let ContactsList = contacts.filter((contact) => contact.id === value);
    if (ContactsList[0] === undefined) {
      ContactsList = historyContacts.filter((contact) => contact.contactId === value);
    }
    let checked = e.target.checked;
    if (checked === true) {
      if (selectedContacts.length <= maxSelect - 1) {
        setSelectedContacts([...selectedContacts, ContactsList[0]]);
      } else {
        toast.error(i18n.t('campaignModal.contacts.notices.maxSelect', { max: maxSelect }));
      }
    } else if (checked === false) {
      setSelectedContacts((current) => current.filter((contact) => contact.id !== value));
    }
  };

  const unselectHistory = () => {
    let notSentContacts = selectedContacts.filter((selectedContact) => {
      return !historyContacts.some((historyContact) => {
        return selectedContact.id === historyContact.id;
      });
    });
    setSelectedContacts(notSentContacts);
  };
  const unselectAll = () => {
    setSelectedContacts([]);
  };

  const handleSelectMax = () => {
    if (selectedContacts.length >= maxSelect) {
      toast.error(i18n.t('campaignModal.contacts.notices.maxSelect', { max: maxSelect }));
    } else {
      const newContacts = getFilteredContacts().slice(0, maxSelect - selectedContacts.length);
      setSelectedContacts([...selectedContacts, ...newContacts]);
    }
  };

  const getFilteredContacts = (ignoreSelecteds) => {
    const contactsFiltered = () => {
      if (ignoreSent) {
        return contacts
          .filter((contact) => !selectedContacts.some((selectedContact) => selectedContact.id === contact.id) && !historyContacts.some((sentContact) => sentContact.id === contact.id))
      } else if (!ignoreSent) {
        return contacts
          .filter((contact) => !selectedContacts.some((selectedContact) => selectedContact.id === contact.id))
      }
    }
    return contactsFiltered();
  }

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };
  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
      //setLoadingContacts(true);
    }
  };

  const handleSearch = (event) => {
    setSearchParam(event.target.value);
  };

  const handleChangeFilter = (event) => {
    setTagFilter(event.target.value);
  };

  const handleCloseImportModal = () => {
    setImportModalOpen(false);
    setImportFile(null);
  }

  const handleFileChange = (e) => {
    const maxFileSize = (5 * 1024 * 1024);

    const file = e.target.files[0];
    if (file.size > maxFileSize) {
      toast.error(`Arquivo muito grande! O tamanho máximo de envio de arquivos é de ${(maxFileSize / (1024 * 1024)).toFixed(2)} MB.`);
    } else if (file) {
      setImportFile(file);
      setImportModalOpen(true); // Abre o modal quando o arquivo é selecionado
    } else {
      toast.error(i18n.t("Não foi possível importar arquivo!"))
    }
    e.target.value = null;
  };

  const handleImportedContacts = (newContacts) => {
    setSelectedContacts(prevSelectedContacts => {
      const existingIds = new Set(prevSelectedContacts.map(contact => contact.id));
      const filteredContacts = newContacts.filter(contact => !existingIds.has(contact.id));
      return [...prevSelectedContacts, ...filteredContacts];
    });
  };

  const handleDownloadExampleCSV = () => {
    downloadExampleCSV();
  }

  return (<>
    <div className={classes.inlineWrapper}>
      <Paper variant="outlined" style={{ flex: "1 0 60%" }} >
        <div className={classes.searchBar}>
          <TextField
            placeholder={i18n.t("contacts.searchPlaceholder")}
            type="search"
            variant="outlined"
            margin="dense"
            fullWidth
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          {tags?.length > 0 && <FormControl variant="outlined" margin='dense' className={classes.formControl}>
            <InputLabel>{i18n.t("tags.title")}</InputLabel>
            <Select
              value={tagFilter}
              onChange={handleChangeFilter}
              label={i18n.t("tags.title")}
            >
              <MenuItem value={''}>&nbsp;</MenuItem>
              {tags.map(tag =>
                <MenuItem value={tag.name}>{tag.name}</MenuItem>
              )}
            </Select>
          </FormControl>}
        </div>
        <Typography style={{ padding: "0 10px" }} variant="body2" gutterBottom>
          {i18n.t("campaignModal.contacts.notices.showingResults", { count: contacts.length, total: count })}
        </Typography>
        <Divider />
        <div style={{ overflowY: "scroll", height: "35vh" }} onScroll={handleScroll}>
          <FormGroup>
            <Table size="small">
              <TableHead>
                <TableRow className={classes.tableCustomRow} >
                  <TableCell align="center" padding="checkbox" />
                  <TableCell align="center" padding="checkbox" />
                  <TableCell align="center" padding="checkbox" />
                  <TableCell align="left">
                    {i18n.t("campaigns.table.name")}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t("campaigns.table.whatsapp")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {contacts?.length > 0 &&
                  contacts.map(contact => !contact.isGroup && (
                    <TableRow className={classes.tableCustomRow}>
                      <TableCell align="center" padding="checkbox">
                        <FormControlLabel
                          control={
                            <Checkbox
                              size="small"
                              color="primary"
                              value={contact.id}
                              checked={selectedContacts.some(current => current.id === contact.id)}
                              onChange={handleChange}
                              name={contact.number}

                            />
                          }
                        />
                      </TableCell>
                      <TableCell align="center" padding="checkbox">
                        {historyContacts.some(current => current.id === contact.id) ? (
                          <Tooltip title={i18n.t("campaignModal.contacts.notices.alrdSent")}>
                            <Loop />
                          </Tooltip>
                        ) : (
                          "")}
                      </TableCell>

                      <TableCell align="center">
                        {contact.extraInfo?.length > 0 ?
                          <AvatarTooltip
                            arrow
                            icon={<Info />}
                            placement="right"
                            title={
                              <>
                                {contact.extraInfo?.length > 0 && contact.extraInfo.map((info) =>
                                  <Typography>
                                    {`${info.name}: ${info.value}`}
                                  </Typography>
                                )}
                              </>
                            }
                          >
                            <Avatar src={contact.profilePicUrl} />
                          </AvatarTooltip>
                          : <Avatar src={contact.profilePicUrl} />
                        }

                      </TableCell>
                      <TableCell align="left">
                        <Typography
                          noWrap
                          style={{ maxWidth: 150, fontSize: 14 }}
                        >
                          {contact.name}
                          {contact.company &&
                            <div>
                              <Typography
                                noWrap
                                style={{ marginTop: "-5px", maxWidth: 150, fontSize: 12 }}
                                color="textSecondary"
                              >
                                {`${contact.company}`}
                              </Typography>
                            </div>
                          }
                          <div className={classes.chipContainer}>
                            {contact.tags && contact?.tags.map(tag => (
                              <Chip style={{ backgroundColor: tag.color, height: '13px', margin: '0px 2px', fontSize: '11px' }} size='small' label={tag?.name} />
                            ))}
                          </div>
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{contact.number} </TableCell>
                    </TableRow>
                  ))}{loading && <TableRowSkeleton checkboxes={2} avatar={1} columns={2} />}
              </TableBody>
            </Table>
          </FormGroup>
        </div>
        <Divider />
        <div className={classes.spaceBetween} >

          <Button
            disabled={getFilteredContacts().slice(0, maxSelect - selectedContacts.length).length < 1}
            onClick={handleSelectMax}
          //variant="outlined"
          >
            {i18n.t('campaignModal.contacts.buttons.selectMax', { max: getFilteredContacts().slice(0, maxSelect - selectedContacts.length).length })}
          </Button>
          <FormControlLabel
            label="Ignorar já enviados"
            control={
              <Checkbox
                size="small"
                checked={ignoreSent}
                //indeterminate={checked[0] !== checked[1]}
                onChange={() => setIgnoreSent((prevState) => !prevState)}
              />
            }
          />
        </div>

      </Paper>

      <Paper variant='outlined' style={{ flex: "1 0 39%", overflow: "hidden" }}>
        <Box sx={{ width: '100%' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                //value={value}
                variant="fullWidth"
                onChange={handleChangeTabs}
                textColor="primary"
                indicatorColor="primary"
              >
                <Tab value="selected" label={i18n.t('campaignModal.contacts.selecteds')} />
                <Tab value="history" label={i18n.t('campaignModal.contacts.sents')} />
              </TabList>
            </Box>
            <TabPanel value="selected" style={{ padding: 0 }}>
              <>
                <Typography style={{ padding: "2px 8px" }} variant="body2" gutterBottom>
                  {`${i18n.t('campaignModal.contacts.notices.selecteds')} ${selectedContacts.length} (Max: ${maxSelect})`}
                </Typography>
                <Divider />
                <div style={{ overflowY: "scroll", height: "35vh" }}>
                  <FormGroup row>
                    <Table size="small">
                      <TableHead>
                        <TableRow className={classes.tableCustomRow}>
                          <TableCell padding="checkbox" />
                          <TableCell padding="checkbox" />
                          <TableCell align="left">{i18n.t("campaigns.table.name")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedContacts?.length > 0 &&
                          selectedContacts.map(contact => (
                            <TableRow className={classes.tableCustomRow} key={contact.id}>
                              <TableCell align="center" padding="checkbox">
                                <FormControlLabel
                                  control={<Checkbox
                                    size="small"
                                    color="primary"
                                    value={contact.id}
                                    checked={selectedContacts.some(current => current.id === contact.id)}
                                    onChange={handleChange}
                                    name={contact.number} />} />
                              </TableCell>
                              <TableCell style={{ paddingRight: 0 }}>
                                {contact.extraInfo?.length > 0 ?
                                  <AvatarTooltip
                                    arrow
                                    icon={<Info />}
                                    placement="right"
                                    title={<>
                                      {contact.extraInfo?.length > 0 && contact.extraInfo.map((info) => <Typography>
                                        {`${info.name}: ${info.value}`}
                                      </Typography>
                                      )}
                                    </>}
                                  >
                                    <Avatar src={contact.profilePicUrl} />
                                  </AvatarTooltip>
                                  : <Avatar src={contact.profilePicUrl} />}
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  noWrap
                                  style={{ maxWidth: 150, fontSize: 14 }}
                                >
                                  {contact.name}
                                  {contact.company &&
                                    <div>
                                      <Typography
                                        noWrap
                                        style={{ marginTop: "-5px", maxWidth: 150, fontSize: 12 }}
                                        color="textSecondary"
                                      >
                                        {`${contact.company}`}
                                      </Typography>
                                    </div>}
                                  <div className={classes.chipContainer}>
                                    {contact.tags && contact?.tags.map(tag => (
                                      <Chip style={{ backgroundColor: tag.color, height: '13px', margin: '0px 2px', fontSize: '11px' }} size='small' label={tag?.name} />
                                    ))}
                                  </div>
                                </Typography>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </FormGroup>
                </div>
                <Divider />
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>

                  <Button
                    disabled={selectedContacts.length < 1}
                    onClick={unselectAll}
                  //variant="outlined"
                  >
                    {i18n.t('campaignModal.contacts.buttons.unselectAll')}
                  </Button>
                  <ButtonGroup variant="outlined">
                    <Button component="label">
                      {i18n.t('campaignModal.contacts.buttons.importCSV')}
                      <input
                        type="file"
                        accept=".csv"
                        hidden
                        onChange={handleFileChange}
                      />
                    </Button>
                    <Button
                      onClick={handleDownloadExampleCSV}
                      endIcon={<SimCardDownloadOutlined />}
                    >Modelo</Button>
                  </ButtonGroup>
                </div>

              </>
            </TabPanel>
            <TabPanel value="history" style={{ padding: 0 }}>

              <>
                <Typography style={{ padding: "2px 8px" }} variant="body2" gutterBottom>
                  {`${i18n.t('campaignModal.contacts.notices.sents')} ${historyContacts.length}`}
                </Typography>
                <Divider />
                <div style={{ overflowY: "scroll", height: "35vh" }}>
                  <FormGroup row>
                    <Table size="small">
                      <TableHead>
                        <TableRow className={classes.tableCustomRow}>
                          <TableCell padding="checkbox" />
                          <TableCell padding="checkbox" />
                          <TableCell align="center">{i18n.t("campaigns.table.name")}</TableCell>
                          <TableCell align="center">{i18n.t("campaigns.table.lastSent")}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {historyContacts?.length > 0 &&
                          historyContacts.map(contact => (
                            <TableRow className={classes.tableCustomRow} key={contact.id}>
                              <TableCell align="center" padding="checkbox">
                                <FormControlLabel
                                  control={<Checkbox
                                    size="small"
                                    color="primary"
                                    value={contact.id}
                                    checked={selectedContacts.some((current) => current.id === contact.id)}
                                    onChange={handleChange}
                                    name={contact.number} />} />
                              </TableCell>
                              <TableCell align="center" padding="checkbox">
                                {contact.extraInfo?.length > 0 ?
                                  <AvatarTooltip
                                    arrow
                                    icon={<Info />}
                                    placement="right"
                                    title={<>
                                      {contact.extraInfo?.length > 0 && contact.extraInfo.map((info) => <Typography>
                                        {`${info.name}: ${info.value}`}
                                      </Typography>
                                      )}
                                    </>}
                                  >
                                    <Avatar src={contact.profilePicUrl} />
                                  </AvatarTooltip>
                                  : <Avatar src={contact.profilePicUrl} />}
                              </TableCell>
                              <TableCell align="left">
                                <Typography
                                  noWrap
                                  style={{ maxWidth: 150, fontSize: 14 }}
                                >
                                  {contact.name}
                                  {contact.company &&
                                    <div>
                                      <Typography
                                        noWrap
                                        style={{ marginTop: "-5px", maxWidth: 150, fontSize: 12 }}
                                        color="textSecondary"
                                      >
                                        {`${contact.company}`}
                                      </Typography>
                                    </div>}
                                  <div className={classes.chipContainer}>
                                    {contact.tags && contact?.tags.map(tag => (
                                      <Chip style={{ backgroundColor: tag.color, height: '13px', margin: '0px 2px', fontSize: '11px' }} size='small' label={tag?.name} />
                                    ))}
                                  </div>
                                </Typography>
                              </TableCell>
                              <TableCell align="center">
                                {format(new Date(contact.updated), "dd/MM/yyyy HH:mm")}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </FormGroup>
                </div>
                <Divider />

                <Button
                  onClick={unselectHistory}
                  disabled={historyContacts.length < 1}
                //variant="outlined"
                >
                  {i18n.t('campaignModal.contacts.buttons.unselectAll')}
                </Button>
              </>
            </TabPanel>
          </TabContext>
        </Box>

      </Paper>
      <ImportContactsFromFile
        open={importModalOpen}
        onClose={handleCloseImportModal}
        file={importFile}
        onConfirm={handleImportedContacts}
        maxContacts={maxSelect - selectedContacts.length}
      />



    </div>
  </>
  )
}
export default CampaignContacts;