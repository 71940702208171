import { useTheme } from "@emotion/react";
import { Paper } from "@mui/material";
import { DataGrid, ptBR } from "@mui/x-data-grid";
//import { useState } from "react";
import { i18n } from "../../translate/i18n";
import { Bar, BarChart, CartesianGrid, Tooltip, ResponsiveContainer, XAxis, YAxis } from "recharts";
import CustomTooltip from "../../components/CustomTooltip";
import Title from "../../components/Title";

const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    {
        field: 'name',
        headerName: `${i18n.t("dashboard.charts.name.title")}`,
        width: 200,
    },
    {
        field: 'amount',
        headerName: `${i18n.t("dashboard.charts.ticketCount.title")}`,
        width: 130,
    },

];

const Charts = ({ label, filter, chartData, ...props }) => {
    const { classes } = props;
    const theme = useTheme();
    //const [loading, setLoading] = useState(false);
    //const height = 350;

    return (
        <Paper variant="outlined" style={{
            padding: theme.spacing(2),
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            height: "100%",
            //flexWrap: "wrap",
            overflow: "hidden",
        }}>
            <Title>{label}</Title>
            <div
                style={{
                    //padding: theme.spacing(2),
                    display: "flex",
                    //flexDirection: "row",
                    height: "100%",
                    flexWrap: "wrap-reverse",
                    overflow: "hidden",
                }}
            >
                <div
                    className={classes.data}
                    style={{ flex: "1 0 35%", minWidth: 300, height: 400 }}
                >
                    <DataGrid
                        rows={chartData}
                        columns={columns}
                        pageSize={100}
                        //rowCount={100}
                        //autoPageSize
                        pageSizeOptions={[]}
                        disableRowSelectionOnClick
                        disableColumnSelector
                        localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
                    />
                </div>
                <div
                    className={classes.data}
                    style={{ flex: "1 0 65%", minWidth: 300, height: 400 }}
                >

                    <ResponsiveContainer debounce={1}>
                        <BarChart
                            data={chartData.slice(0, 50)}
                            barSize={40}
                            
                            width={730}
                            height={250}
                            margin={{
                                top: 16,
                                right: 16,
                                bottom: 0,
                                left: 0,
                            }}
                        >
                            <Tooltip
                                cursor={{ fill: theme.palette.divider}}
                                animationEasing={"ease-in-out"}
                                content={<CustomTooltip />}
                            />
                            <XAxis 
                                dataKey="name" 
                                stroke={theme.palette.text.secondary} 
                                axisLine={false}
                            />
                                
                            <YAxis
                                type="number"
                                allowDecimals={false}
                                stroke={theme.palette.text.secondary}
                                axisLine={false} // Remova a linha do eixo Y
                                tickMargin={5} // Defina a margem entre os rótulos do eixo e o eixo Y
                                style={{
                                    left: '-20px', // Ajuste o valor negativo para reduzir a margem esquerda
                                }}
                            />
                            <CartesianGrid
                                vertical={false}
                                stroke={theme.palette.divider}
                            />
                            <Bar dataKey="amount" fill={theme.palette.primary.dark} stackId={"a"} maxBarSize={10} barSize={7} radius={[10, 10, 10, 10]} />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
            </div>
        </Paper>
    );
}
export default Charts;