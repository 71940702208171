import React, { useState, useEffect, useRef } from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import makeStyles from '@mui/styles/makeStyles';
//import { green } from "@mui/material/colors";

import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	DialogActions,
	CircularProgress,
	TextField,
	Switch,
	FormControlLabel,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
	Alert,
} from "@mui/material";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import { capitalizeFirst } from "../../services/stringServices";
//import { Picker } from "emoji-mart";
//import { AttachFileOutlined, Cancel, Mood } from "@mui/icons-material";
import AdvancedTextField from "../AdvancedTextField";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { format } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import useIntegrations from "../../hooks/useIntegrations";


const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multFieldLine: {
		display: "flex",
		justifyContent: "space-evenly",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: theme.palette.primary.main,
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
		//paddingBottom: "60px !important",
		"& textarea": {
			marginBottom: "30px !important",
		}
	},
	iconButton: {
		color: theme.palette.text.secondary
	},



}));

const SessionSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Muito curto!")
		.max(50, "Muito longo")
		.required("Obrigatório!"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId, connType }) => {
	const classes = useStyles();
	//const messageRef = useRef({});
	const initialState = {
		name: "",
		greetingMessage: "",
		farewellMessage: "",
		reviewMessage: "",
		awayMessage: "",
		isDefault: false,
		importHistory: false,
		importHistoryStartDate: new Date(1577836800000),
		importHistoryEndDate: new Date(),
		connType: connType,
		aiMode: false,
		aiId: "",
		movideskId: ""
	};
	const { integrations } = useIntegrations({ showAll: true });
	const integratedAis = integrations.filter((integration) => integration.type === "openai" || integration.type === "typebot");
	const integratedMovidesks = integrations.filter((integration) => integration.type === "movidesk");
	const [whatsApp, setWhatsApp] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [greetingsMedias, setGreetingMedias] = useState([]);
	const [farewellMedias, setFarewellMedias] = useState([]);
	const [reviewMedias, setReviewMedias] = useState([]);
	const [awayMessageMedias, setAwayMessageMedias] = useState([]);
	const textTemplates = {
		greeting: "Bom dia/Boa tarde/Boa noite",
		contactName: "Nome do contato",
		contactNumber: "Número do contato",
		contactEmail: "Email do contato",
		ticketNumber: "Número do ticket atual",
	}
	const textTemplatesWithUsr = {
		...textTemplates,
		userName: "Nome do atendente"
	}

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {

			const fetchSession = async () => {
				if (!whatsAppId) return;

				try {
					const { data } = await api.get(`whatsapp/${whatsAppId}`);
					setWhatsApp(data);

					const whatsQueueIds = data.queues?.map(queue => queue.id);
					setSelectedQueueIds(whatsQueueIds);
				} catch (err) {
					toastError(err);
				}
			};
			fetchSession();
		}, 500)
		return () => clearTimeout(delayDebounceFn)
	}, [whatsAppId]);

	const handleSaveWhatsApp = async (values) => {
		const whatsappData = new FormData();
		whatsappData.append("name", values.name);
		whatsappData.append("greetingMessage", values.greetingMessage);
		whatsappData.append("farewellMessage", values.farewellMessage);
		whatsappData.append("awayMessage", values.awayMessage);
		whatsappData.append("reviewMessage", values.reviewMessage);
		whatsappData.append("isDefault", values.isDefault);
		whatsappData.append("importHistory", values.importHistory);
		if (values.importHistoryStartDate) whatsappData.append("importHistoryStartDate", values.importHistoryStartDate);
		if (values.importHistoryEndDate) whatsappData.append("importHistoryEndDate", values.importHistoryEndDate);
		whatsappData.append("aiMode", values.aiMode);
		if (values.aiId) whatsappData.append("aiId", values.aiId);
		if (values.movideskId) whatsappData.append("movideskId", values.movideskId);
		whatsappData.append("queueIds", selectedQueueIds);
		if (values.connType) whatsappData.append("connectionType", connType);
		if (greetingsMedias) {
			greetingsMedias.forEach((media) => {
				whatsappData.append("greetingMedia", media);
			});
		}
		if (farewellMedias) {
			farewellMedias.forEach((media) => {
				whatsappData.append("farewellMedia", media);
			});
		}
		if (reviewMedias) {
			reviewMedias.forEach((media) => {
				whatsappData.append("reviewMedia", media);
			});
		}
		if (awayMessageMedias) {
			awayMessageMedias.forEach((media) => {
				whatsappData.append("awayMessageMedia", media);
			});
		}
		try {
			if (whatsAppId) {
				await api.put(`/${connType}/${whatsAppId}`, whatsappData)
			} else {
				await api.post(`/${connType}`, whatsappData);
			}
			toast.success(i18n.t("whatsappModal.success"));
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	const handleClose = () => {
		onClose();
		setGreetingMedias([]);
		setFarewellMedias([]);
		setAwayMessageMedias([]);
		setReviewMedias([]);
		setSelectedQueueIds([]);
		setWhatsApp(initialState);
	};

	return (
		<div className={classes.root}>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<Dialog
					open={open}
					onClose={handleClose}
					maxWidth="sm"
					fullWidth
					scroll="paper"
				>
					<DialogTitle
						color="textPrimary"
					>
						{whatsAppId
							? i18n.t("whatsappModal.title.edit", { connType: connType === "bwhatsapp" ? "Whatsapp" : capitalizeFirst(connType) + `${connType === "whatsapp" ? " Alt." : ""}` })
							: i18n.t("whatsappModal.title.add", { connType: connType === "bwhatsapp" ? "Whatsapp" : capitalizeFirst(connType) + `${connType === "whatsapp" ? " Alt." : ""}` })}
					</DialogTitle>
					<Formik
						initialValues={whatsApp}
						enableReinitialize={true}
						validationSchema={SessionSchema}
						onSubmit={(values, actions) => {
							setTimeout(() => {
								handleSaveWhatsApp(values);
								actions.setSubmitting(false);
							}, 400);
						}}
					>
						{({ values, touched, errors, isSubmitting }) => (
							<Form>
								<DialogContent dividers>
									<div style={{ maxHeight: "55vh" }}>
										<div className={classes.multFieldLine}>
											<Field
												as={TextField}
												label={i18n.t("whatsappModal.form.name")}
												autoFocus
												fullWidth
												name="name"
												error={touched.name && Boolean(errors.name)}
												helperText={touched.name && errors.name}
												variant="outlined"
												margin="dense"
												className={classes.textField}
											/>
											{(connType === 'whatsapp' || connType === 'bwhatsapp') &&

												<FormControlLabel
													control={
														<Field
															as={Switch}
															color="primary"
															name="isDefault"
															checked={values.isDefault}
														/>
													}
													label={i18n.t("whatsappModal.form.default")}
												/>

											}
										</div>
										{(connType === 'bwhatsapp') && <>
											<div className={classes.multFieldLine}>
												<FormControlLabel
													control={
														<Field
															as={Switch}
															color="primary"
															name="importHistory"
															checked={values.importHistory}
														/>
													}
													label={i18n.t("whatsappModal.form.history")}
												/>
												<Field
													as={DateTimePicker}
													label={i18n.t("whatsappModal.form.historyStart")}
													type="datetime-local"
													format="dd/MM/yyyy HH:mm"
													value={new Date(values.importHistoryStartDate)}
													ampm={false}
													disableFuture
													onChange={(date) => values.importHistoryStartDate = format(new Date(date), "yyyy-MM-dd'T'HH:mm")}
													slotProps={{
														textField: {
															margin: "dense",
															error: touched.importHistoryStartDate && Boolean(errors.importHistoryStartDate),
															helperText: touched.importHistoryStartDate && errors.importHistoryStartDate ? errors.importHistoryStartDate : ""
														}
													}}
													InputLabelProps={{
														shrink: true,
													}}
												/>
												<Field
													as={DateTimePicker}
													label={i18n.t("whatsappModal.form.historyEnd")}
													type="datetime-local"
													format="dd/MM/yyyy HH:mm"
													value={new Date(values.importHistoryEndDate)}
													ampm={false}
													disableFuture
													onChange={(date) => values.importHistoryEndDate = format(new Date(date), "yyyy-MM-dd'T'HH:mm")}
													slotProps={{
														textField: {
															margin: "dense",
															error: touched.importHistoryEndDate && Boolean(errors.importHistoryEndDate),
															helperText: touched.importHistoryEndDate && errors.importHistoryEndDate ? errors.importHistoryEndDate : ""
														}
													}}
													InputLabelProps={{
														shrink: true,
													}}
												/>

											</div>
											{values.importHistory &&
												<Alert severity="warning">{i18n.t("whatsappModal.notices.warning")}</Alert>
											}
										</>}
										<Field
											as={AdvancedTextField}
											selectFiles={true}
											selectTemplates={true}
											templates={textTemplates}
											label={i18n.t("queueModal.form.greetingMessage")}
											type="message"
											multiline
											fullWidth
											margin="dense"
											onChange={(value, medias) => {
												values.greetingMessage = value
												setGreetingMedias(medias);
											}}
											rows={4}
											id="greetingMessage"
											name="greetingMessage"
											uploadName="greetingMediaUrl"
											uploadValue={values.greetingMediaUrl}
											className={classes.textField}
											variant="outlined"
										/>

										<Field
											as={AdvancedTextField}
											selectFiles={true}
											selectTemplates={true}
											templates={textTemplatesWithUsr}
											label={i18n.t("whatsappModal.form.farewellMessage")}
											type="message"
											multiline
											fullWidth
											margin="dense"
											onChange={(value, medias) => {
												values.farewellMessage = value
												setFarewellMedias(medias);
											}}
											rows={4}
											id="farewellMessage"
											name="farewellMessage"
											uploadName="farewellMediaUrl"
											uploadValue={values.farewellMediaUrl}
											className={classes.textField}
											variant="outlined"
										/>
										<Field
											as={AdvancedTextField}
											selectFiles={true}
											selectTemplates={true}
											templates={textTemplatesWithUsr}
											label={i18n.t("whatsappModal.form.reviewMessage")}
											type="message"
											multiline
											fullWidth
											margin="dense"
											onChange={(value, medias) => {
												values.reviewMessage = value
												setReviewMedias(medias);
											}}
											rows={4}
											id="reviewMessage"
											name="reviewMessage"
											uploadName="reviewMessageMediaUrl"
											uploadValue={values.reviewMessageMediaUrl}
											className={classes.textField}
											variant="outlined"
										/>
										<Field
											as={AdvancedTextField}
											selectFiles={true}
											selectTemplates={true}
											templates={textTemplates}
											label={i18n.t("whatsappModal.form.awayMessage")}
											type="message"
											multiline
											fullWidth
											margin="dense"
											onChange={(value, medias) => {
												values.awayMessage = value
												setAwayMessageMedias(medias);
											}}
											rows={2}
											id="awayMessage"
											name="awayMessage"
											uploadName="awayMessageMediaUrl"
											uploadValue={values.awayMessageMediaUrl}
											className={classes.textField}
											variant="outlined"
										/>
										<FormControlLabel
											control={
												<Field
													name="aiMode"
													as={Switch}
													color="primary"
													checked={values.aiMode}
												/>
											}
											label={values.aiMode ? i18n.t("whatsappModal.form.aiMode") : i18n.t("whatsappModal.form.queueMode")}
										/>
										{values.aiMode ?
											<>
												<FormControl
													className={classes.multiLine}
													variant="outlined"
													margin="dense"
													fullWidth
													error={Boolean(errors.aiId)}
												>
													<InputLabel>{i18n.t("whatsappModal.form.integratedAi")}</InputLabel>
													<Field
														label={i18n.t("whatsappModal.form.integratedAi")}
														as={Select}
														color="primary"
														name="aiId"
													>
														{integratedAis.map((ai) =>
															<MenuItem value={ai.id}>{ai.name}</MenuItem>)
														}
													</Field>
													<FormHelperText>{errors.aiId}</FormHelperText>
												</FormControl>
											</>
											:
											<QueueSelect
												selectedQueueIds={selectedQueueIds}
												onChange={selectedIds => setSelectedQueueIds(selectedIds)}
											/>
										}
										{integratedMovidesks.length > 0 &&
											<>
												<FormControl
													className={classes.multiLine}
													variant="outlined"
													margin="dense"
													fullWidth
													error={Boolean(errors.movideskId)}
												>
													<InputLabel>{i18n.t("whatsappModal.form.integratedMovidesk")}</InputLabel>
													<Field
														label={i18n.t("whatsappModal.form.integratedMovidesk")}
														as={Select}
														color="primary"
														name="movideskId"
													>
														<MenuItem value={null}>&nbsp;</MenuItem>
														{integratedMovidesks.map((md) =>
															<MenuItem value={md.id}>{md.name}</MenuItem>)
														}
													</Field>
													<FormHelperText>{errors.movideskId}</FormHelperText>
												</FormControl>
											</>
										}
									</div>
								</DialogContent>
								<DialogActions>
									<Button
										onClick={handleClose}
										color="secondary"
										disabled={isSubmitting}
										variant="outlined"
									>
										{i18n.t("whatsappModal.buttons.cancel")}
									</Button>
									<Button
										type="submit"
										color="primary"
										disabled={isSubmitting}
										variant="contained"
										className={classes.btnWrapper}
									>
										{whatsAppId
											? i18n.t("whatsappModal.buttons.okEdit")
											: i18n.t("whatsappModal.buttons.okAdd")}
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</Button>
								</DialogActions>
							</Form>
						)}
					</Formik>
				</Dialog>
			</LocalizationProvider>
		</div>
	);
};

export default React.memo(WhatsAppModal);
