
import { toast } from "react-toastify";
import { i18n } from "../translate/i18n";

export const downloadExampleCSV = () => {
  const csvContent = 'name,number,tags,email,company,obs\n"John Doe","555533334444","Tag1,Tag2,Tag3","john.doe@example.com","Company A","Observação A"\n"Jane Smith","555511112222","TagA,TagB","jane.smith@example.com","Company B","Observação B"';
  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `import_example.csv`;
  a.click();
  URL.revokeObjectURL(url);
  toast.success(i18n.t("reviews.toasts.CSVsuccess"));
};
