import React from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import makeStyles from '@mui/styles/makeStyles';
import TicketsManager from "../../components/TicketsManager/";
import Ticket from "../../components/Ticket/";
import Hidden from "@mui/material/Hidden";
import { SearchProvider } from "../../context/Search/SearchContext";
import { SettingsProvider } from "../../context/Settings/SettingsContext";
import { TagsProvider } from "../../context/Tags/TagsContext";
import QuickChat from "../../components/QuickChat";
//import useSettings from "../../hooks/useSettings";


const useStyles = makeStyles((theme) => ({
  chatContainer: {
    flex: 1,
    //backgroundColor: "#eee",
    margin: theme.spacing(1),
    height: `calc(100% - ${theme.spacing(8)})`,
    overflowY: "hidden",
  },

  chatPapper: {
    // backgroundColor: "red",
    display: "flex",
    height: "100%",

  },

  contactsWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
  },
  contactsWrapperSmall: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflowY: "hidden",
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
  },
  messagessWrapper: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
  },
  welcomeMsg: {
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    height: "100%",
    textAlign: "center",
    borderRadius: 0,
  },
  ticketsManager: {

  },
  ticketsManagerClosed: {
    [theme.breakpoints.down('md')]: {
      display: "none",
    },
  },
}));

const Chat = () => {
  const classes = useStyles();
  const { ticketId } = useParams();
  //const { settings } = useSettings();

  return (
      <TagsProvider>
    <SettingsProvider>
        <SearchProvider>

          <Paper variant="outlined" className={classes.chatContainer}>
            <div className={classes.chatPapper}>
              <Grid container spacing={0}>
                {/* <Grid item xs={4} className={classes.contactsWrapper}> */}
                <Grid
                  item
                  xs={12}
                  md={4}
                  className={
                    ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper
                  }
                >
                  <TicketsManager />
                </Grid>
                <Grid item xs={12} md={8} className={classes.messagessWrapper}>
                  {/* <Grid item xs={8} className={classes.messagessWrapper}> */}
                  {ticketId ? (
                    <>
                      <Ticket />
                    </>
                  ) : (
                    <Hidden only={["sm", "xs"]}>
                      <Paper className={classes.welcomeMsg}>
                        {/* <Paper square variant="outlined" className={classes.welcomeMsg}> */}
                        {/* <span>{i18n.t("chat.noTicketMessage")}</span> */}
                        <QuickChat />
                      </Paper>
                    </Hidden>
                  )}
                </Grid>
              </Grid>
            </div>
          </Paper>

        </SearchProvider>
    </SettingsProvider>
      </TagsProvider>
  );
};

export default Chat;
