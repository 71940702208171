import openSocket, { Socket } from "socket.io-client";
import { getBackendUrl } from "../config";
import toastError from "../errors/toastError";
import { authenticated } from "../hooks/useAuth";

function connectToSocket() {
    const storedToken = localStorage.getItem("wstoken");

    try {
        const token = storedToken ? JSON.parse(storedToken) : "";
        let socket = openSocket(getBackendUrl(), {
            withCredentials: true,
            auth: { token },
            reconnection: true,
            reconnectionAttempts: 5,
            reconnectionDelay: 5000,
            timeout: 10000
        });
        return  socket;
    }
    catch (err) {
        toastError(err);
    }
}

export default connectToSocket;