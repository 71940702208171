import React, { useState } from 'react';
import {
  Checkbox,
  Collapse,
  FormControlLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { ExpandLess, ExpandMore } from '@mui/icons-material';


const useStyles = makeStyles((theme) => ({
  iconButton: {
    color: theme.palette.text.secondary
  },
}))
const ExpandableList = ({ icon, label, items, selectedItemsId, onChange }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleChange = (itemId) => {
    let newValues;
    if (selectedItemsId.includes(itemId)) {
      newValues = selectedItemsId.filter((value) => value !== itemId);
    } else {
      newValues = [...selectedItemsId, itemId];
    }
    onChange(newValues);
  };
  const handleDeselectSelectAll = () => {
    if (selectedItemsId.length > 0) {
      onChange([]);
    } else {
      onChange(items.map((item) => item.id))
    }
  }

  return (
    items && items?.length > 0 ?
      <>
        <MenuItem onClick={handleClick}>
          <ListItemIcon className={classes.iconButton}>
            {icon}
          </ListItemIcon>
          <ListItemText primary={label} />
          {open ? <ExpandLess className={classes.iconButton} /> : <ExpandMore className={classes.iconButton} />}
        </MenuItem>
        <Collapse in={open} timeout="auto" unmountOnExit style={{ maxHeight: '200px', overflowY: 'scroll' }}>

          <MenuList dense component="div" disablePadding >
            <MenuItem onClick={() => handleDeselectSelectAll()}>
              <ListItemIcon>
                <FormControlLabel
                  control={<Checkbox
                    checked={selectedItemsId.length === items.length}
                    indeterminate={selectedItemsId.length > 0 && selectedItemsId.length <= (items.length - 1)}
                    size='small'
                  />}
                />
              </ListItemIcon>
              <ListItemText primary={selectedItemsId.length > 0 ? "Sem Categoria" : "Voltar todos"} />
            </MenuItem>
            {items && items.map((item, index) =>
              <MenuItem key={index} onClick={() => handleChange(item.id)}>
                <ListItemIcon>
                  <FormControlLabel
                    control={<Checkbox
                      style={{
                        color: item?.color ? item?.color : "#ffffff",
                      }}
                      value={item.id}
                      checked={selectedItemsId.indexOf(item.id) > -1}
                      size='small'
                    />}
                  />
                </ListItemIcon>
                <ListItemText primary={item?.name} />
              </MenuItem>
            )
            }

          </MenuList>
        </Collapse>
      </>
      : <></>

  )
}

export default ExpandableList;