import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { FormControlLabel, FormHelperText, Switch } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Autocomplete, {
	createFilterOptions,
} from '@mui/material/Autocomplete';
import CircularProgress from "@mui/material/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import useQueues from "../../hooks/useQueues";
import useWhatsApps from "../../hooks/useWhatsApps";
import { SettingsContext } from "../../context/Settings/SettingsContext";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: "100%",
	},
}));

const filterOptions = createFilterOptions({
	trim: true,
});

const TransferTicketModal = ({ modalOpen, onClose, ticketid, isGroup, ticketWhatsappId, isWhatsapp }) => {
	const history = useHistory();
	const [options, setOptions] = useState([]);
	const [queues, setQueues] = useState([]);
	const [allQueues, setAllQueues] = useState([]);
	const { findAll: findAllQueues } = useQueues();
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedQueue, setSelectedQueue] = useState('');
	const [selectedWhatsapp, setSelectedWhatsapp] = useState(ticketWhatsappId);
	const [transferMsg, setTransferMsg] = useState(true);
	const { user } = useContext(AuthContext);
	const classes = useStyles();
	const { loadingWhatsapps, whatsApps } = useWhatsApps();
	const { settings } = useContext(SettingsContext);


	const getSettingValue = (key) => {
		if (settings && settings.length > 0) {
			const { value } = settings.find(s => s.key === key);
			return value;
		}
	};

	useEffect(() => {
		const loadQueues = async () => {
			const list = await findAllQueues();
			setAllQueues(list);
			setQueues(list);
		}
		loadQueues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchUsers = async () => {
				try {
					const { data } = await api.get("/users/", {
						params: { searchParam },
					});
					setOptions(data.users);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchUsers();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen]);

	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedUser(null);
	};

	const canView = Can({
		role: user.profile,
		perform: "drawer-admin-items:view",
		yes: () => true,
		no: () => false,
	});

	const anonymousTicket = () => {
		const canView = Can({
			role: user.profile,
			perform: "drawer-admin-items:view",
			yes: () => true,
			no: () => false,
		});
		const isAnonymous = getSettingValue('anonymoustickets')
		if (isAnonymous === 'enabled' && !canView) {
			return true;
		} else if (isAnonymous === 'disabled' || canView) {
			return false;
		} else return false;
	};

	const transferMsgEnabled = getSettingValue('transfermsg') !== 'disabled' ? true : false;
	const showSwitchToAllUsers = getSettingValue('showtransferswitch') === 'enabled' ? true : false;
	const groupsAsTicket = getSettingValue('groupsasticket') === 'enabled'? true:false;

	const transferSwitchVisibilityControl = () => {
		return (
			(transferMsgEnabled && showSwitchToAllUsers && canView && !isGroup) ||
			(transferMsgEnabled && !showSwitchToAllUsers && canView && !isGroup) ||
			(transferMsgEnabled && showSwitchToAllUsers && !canView && !isGroup)
		)
	}

	const handleSaveTicket = async e => {
		e.preventDefault();
		if (!ticketid) return;
		setLoading(true);
		try {
			let data = {};

			if (selectedUser) {
				data.userId = selectedUser.id;
				data.transf = transferMsg;
			} else {
				if (!isGroup || groupsAsTicket){
					data.status = 'pending';
				}
				data.userId = null;
				data.transf = transferMsg;
			}
			if (selectedQueue && selectedQueue !== null) {
				data.queueId = selectedQueue
			} else {
				data.queueId = null;
			}
			if (selectedWhatsapp) {
				data.whatsappId = selectedWhatsapp;
			}

			await api.put(`/tickets/${ticketid}`, data);

			setLoading(false);
			history.push(`/tickets`);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		handleClose();
	};
	return (
		<Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" scroll="paper">
			<form onSubmit={handleSaveTicket}>
				<DialogTitle
					color="textPrimary"
					id="form-dialog-title"
				>
					{i18n.t("transferTicketModal.title")}
				</DialogTitle>
				<DialogContent dividers>
					<Autocomplete

						className={classes.maxWidth}
						getOptionLabel={option => `${option.name}`}
						onChange={(e, newValue) => {
							setSelectedUser(newValue);
							if (newValue != null && Array.isArray(newValue.queues)) {
								setQueues(newValue.queues);
							} else {
								setQueues(allQueues);
								setSelectedQueue('');
							}
						}}
						options={options}
						filterOptions={filterOptions}
						freeSolo
						autoHighlight
						noOptionsText={i18n.t("transferTicketModal.noOptions")}
						loading={loading}
						renderInput={params => (
							<TextField
								{...params}
								fullWidth
								required={anonymousTicket()}
								margin="dense"
								label={i18n.t("transferTicketModal.fieldLabel")}
								variant="outlined"
								autoFocus
								onChange={e => setSearchParam(e.target.value)}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20} />
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>
					{queues && queues.length > 0 && (
						<FormControl variant="outlined" className={classes.maxWidth} margin="dense" error={selectedUser !== null && !selectedQueue}>
							<InputLabel id="select-queue">{i18n.t("transferTicketModal.fieldQueueLabel")}</InputLabel>
							<Select
								value={selectedQueue}
								onChange={(e) => setSelectedQueue(e.target.value)}
								label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
								labelId="select-queue"
								required={selectedUser !== null && !selectedQueue}
							>
								<MenuItem value={''}>&nbsp;</MenuItem>
								{queues && queues.map((queue) => (
									<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
								))}
							</Select>
							{selectedUser !== null && !selectedQueue &&
								<FormHelperText>{"Obrigatório"}</FormHelperText>
							}
						</FormControl>
					)}
					{!loadingWhatsapps && whatsApps.some(whatsapp => whatsapp.connectionType === "bwhatsapp" || whatsapp.connectionType === "whatsapp") && isWhatsapp() === true && !isGroup && (
						<FormControl variant="outlined" className={classes.maxWidth} margin="dense">
							<InputLabel>{i18n.t("transferTicketModal.fieldConnectionLabel")}</InputLabel>
							<Select
								value={selectedWhatsapp}
								onChange={(e) => setSelectedWhatsapp(e.target.value)}
								label={i18n.t("transferTicketModal.fieldConnectionPlaceholder")}
							>
								{whatsApps && whatsApps
									.filter(whatsapp => whatsapp.connectionType === "bwhatsapp" || whatsapp.connectionType === "whatsapp")
									.map(whatsapp => (
										<MenuItem key={whatsapp.id} value={whatsapp.id}>{whatsapp.name}</MenuItem>
									))}
							</Select>
						</FormControl>
					)}

					{(user.profile === "admin" || transferSwitchVisibilityControl()) && 
						<FormControlLabel className={classes.maxWidth}
							label={i18n.t("Mensagem de transferência")}
							labelPlacement="end"
							control={
								<Switch
									checked={transferMsg}
									onChange={(e) =>
										setTransferMsg(!transferMsg)
									}
									name="transferMsg"
									color="primary"
								/>
							}
						/>
					}


				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("transferTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
					>
						{i18n.t("transferTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default TransferTicketModal;
