import React, { useContext, useEffect, useRef, useState } from "react";

import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import TransferTicketModal from "../TransferTicketModal";
import toastError from "../../errors/toastError";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import { FormControlLabel, InputBase, Switch, Typography } from "@mui/material";
import { SearchContext } from "../../context/Search/SearchContext";
import ScheduleModal from "../ScheduleModal";
import ExportMsgsModal from "../ExportMsgsModal";
import LogsModal from "../LogsModal";

const TicketOptionsMenu = ({ ticket, menuOpen, handleClose, anchorEl, showHistory, setShowHistory }) => {
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [transferTicketModalOpen, setTransferTicketModalOpen] = useState(false);
	const [scheduleModalOpen, setScheduleModalOpen] = useState(false);
	const [logsModalOpen, setLogsModalOpen] = useState(false);
	const [exportMsgsModalOpen, setExportMsgsModalOpen] = useState(false);
	const [hideFarewell, setShowFarewell] = useState(true);
	const { setIsSearching } = useContext(SearchContext);
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleDeleteTicket = async () => {
		try {
			await api.delete(`/tickets/${ticket.id}`);
		} catch (err) {
			toastError(err);
		}
	};

	const handleOpenConfirmationModal = e => {
		setConfirmationOpen(true);
		handleClose();
	};
	const toggleShowHistory = (e) => {
		setShowHistory();
	}

	const toggleShowFarewell = (e) => {
		setShowFarewell(hideFarewell ? false : true);
	}

	const handleOpenTransferModal = (e) => {
		setTransferTicketModalOpen(true);
		handleClose();
	};
	const handleOpenScheduleModal = (e) => {
		setScheduleModalOpen(true);
		handleClose();
	};

	const handleOpenLogsModal = (e) => {
		setLogsModalOpen(true);
		handleClose();
	};
	const handleOpenExportMsgsModal = (e) => {
		setExportMsgsModalOpen(true);
		handleClose();
	};

	const handleCloseScheduleModal = (e) => {
		if (isMounted.current) {
			setScheduleModalOpen(false);
		}
	};
	const handleCloseLogsModal = () => {
		if (isMounted.current) {
			setLogsModalOpen(false);
		}
	}

	const handleCloseExportMsgsModal = (e) => {
		if (isMounted.current) {
			setExportMsgsModalOpen(false);
		}
	};

	const handleCloseTransferTicketModal = (e) => {
		if (isMounted.current) {
			setTransferTicketModalOpen(false);
		}
	};
	const isWhatsapp = (e) => {
		if (ticket.whatsapp?.connectionType === 'whatsapp'
			|| ticket.whatsapp?.connectionType === 'bwhatsapp'
			/* || !ticket.contact?.connectionId */) {
			return true;
		}
		return false;
	}
	const openSearch = (e) => {
		setIsSearching(true);
		handleClose();
	}
	return (
		<>

			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				//getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={handleClose}
			>
				{ticket.status === "open" && (ticket.userId === user.id || user.profile === 'admin') &&
					<MenuItem onClick={handleOpenTransferModal}>
						{i18n.t("ticketOptionsMenu.transfer")}
					</MenuItem>
				}
				<MenuItem onClick={openSearch}>
					{i18n.t("ticketOptionsMenu.search")}
				</MenuItem>
				{isWhatsapp() &&
					<MenuItem onClick={handleOpenScheduleModal}>
						{i18n.t("ticketOptionsMenu.schedule")}
					</MenuItem>}
				<MenuItem onClick={handleOpenExportMsgsModal}>
					{i18n.t("ticketOptionsMenu.exportMsgs")}
				</MenuItem>
				<Can
					role={user.profile}
					perform="ticket-options:deleteTicket"
					yes={() => (
						<>
							<MenuItem onClick={handleOpenLogsModal}>
								{i18n.t("ticketOptionsMenu.showLogs")}
							</MenuItem>
							<MenuItem onClick={handleOpenConfirmationModal}>
								{i18n.t("ticketOptionsMenu.delete")}
							</MenuItem>
						</>
					)}
				/>
				<MenuItem disableTouchRipple={true}>
					<FormControlLabel
						label={i18n.t("Histórico")}
						control={
							<Switch
								size="small"
								checked={showHistory}
								onChange={() =>
									toggleShowHistory()
								}
								name="showHistory"
								color="primary" />
						}>
					</FormControlLabel>
				</MenuItem>

				{/*<MenuItem disableTouchRipple={true}>
					<FormControlLabel
					label={i18n.t("Despedida")}
					control={
						<Switch
						size="small"
						checked={hideFarewell}
						onChange={(e) =>
							toggleShowFarewell(e)
						}
						name="showFarewell"
						color="primary" />
					}>
						
					</FormControlLabel>
				</MenuItem>*/}


			</Menu>
			<ConfirmationModal
				title={`${i18n.t("ticketOptionsMenu.confirmationModal.title", { ticketId: ticket.id, contactName: ticket.contact?.name })}`}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteTicket}
			>
				{i18n.t("ticketOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>
			<ScheduleModal
				modalOpen={scheduleModalOpen}
				onClose={handleCloseScheduleModal}
				contact={ticket.contact}
			/>
			<TransferTicketModal
				modalOpen={transferTicketModalOpen}
				onClose={handleCloseTransferTicketModal}
				ticketid={ticket.id}
				isGroup={ticket.isGroup}
				isWhatsapp={isWhatsapp}
				ticketWhatsappId={ticket.whatsappId}
			/>
			<LogsModal
				open={logsModalOpen}
				onClose={handleCloseLogsModal}
				ticketId={ticket.id}
			/>
			<ExportMsgsModal
				modalOpen={exportMsgsModalOpen}
				ticketId={ticket.id}
				onClose={handleCloseExportMsgsModal}
			/>
		</>
	);
};

export default TicketOptionsMenu;
